<template>
  <div class="container_cont_box">
    <div class="bc1_home" >
      <img src="../../assets/pc/qnz/bc1_home.png" style="width: 100%;height: 100%;" alt="">
    </div>
    <div style="width: 100%;background: #F7FAFF;">
      <div class="banner-content" v-if="bannerList.length > 0">
        <div class="swiper_banner">
          <swiper class="banner" ref="mySwiper" :options="swiperOptionsBanner">
            <swiper-slide
              v-for="(item, index) in bannerList"
              :key="item.image + '_' + index"
            >
              <a :href="item.link" target="_blank">
                <img class="banner-img" :src="item.image" />
                <!-- <img class="banner-img" src="@/assets/index/banner.png"> -->
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <div class="content_cont_box">
      <img class="bc4_home" src="../../assets/pc/qnz/bc4_home.png" alt="">
      <div>
        <!-- 黔南州工匠大讲堂 -->
        <div class="content-box live-content" v-if="liveModule.length > 0">
          <div class="cont_item clearfix">
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title">工匠大讲堂</p>
              </div>
              <!-- <div
                class="more_btn cursor flex"
                @click="$router.push('/liveList')"
              >
                <span>查看更多</span>
                <img src="@/assets/index/qnzright_icon.png" alt="" />
              </div> -->
            </div>
            <div class="live-list-content">
              <div class="img-show">
                <img :src="liveSelect.image" alt="" @click="getChannelNo" />
              </div>
              <ul class="live-list">
                <li
                  class="live-info"
                  :class="item.id == liveSelect.id ? 'active' : ''"
                  v-for="item in liveModule"
                  :key="item.id"
                  @click="selectLive(item)"
                >
                  <div class="live-title" @click="liveModuleImg = item.image">
                    {{ item.name }}
                    <!-- <router-link :to="'/live/' + item.channel_no">{{
                    
                  }}</router-link> -->
                  </div>
                  <!-- <router-link  :to="'/live/'+item.channel_no" class="live-title">{{item.name}}</router-link> -->
                  <div :class="item.liveNow ? 'live-now' : 'live-date'">
                    {{ item.liveNow ? "正在直播" : item.live_date }}
                  </div>
                  <div class="live-lecturer">
                    主讲人：{{ item.teacher_name }}
                  </div>
                  <!-- <router-link :to="'/live/'+item.channel_no" class="live-btn into" :class="item.liveNow? 'into': 'subscribe'">{{item.liveNow? '进入直播': '立即预约'}}</router-link> -->
                  <router-link
                    v-if="item.liveNow"
                    :to="'/live/' + item.channel_no + '/' + item.id"
                    class="live-btn into"
                    >进入直播</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
         <!-- 大师来了 -->
        <div class="TeacherBox" v-if="showTeacher.length > 0">
          <div v-for="(showItem, showIndex) in showTeacher" :key="showIndex" class="cont_item cont_box2">
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title">{{showIndex == 0 ? '工匠大师进黔南' : '劳模工匠助企行 · 黔南站' }}</p>
              </div>
            </div>
            <div class="teacher_cont_box">
              <div class="show_teacher_info">
                <div class="left_img">
                  <img :src="showItem.avatar_34" alt="" />
                </div>
                <div class="right_cont_info">
                  <div class="name_info">
                    <p class="name">{{ showItem.name }}</p>
                    <p class="level_info flex">
                      <img src="@/assets/pc/qnz/level_icon.png" alt="" />
                      <span>{{ showItem.level }}</span>
                    </p>
                  </div>
                  <div  v-if="showItem.introduce" class="text_info overflow_4">
                    {{ showItem.introduce }}
                  </div>
                  <div class="tips_list">
                    <ul>
                      <li v-for="(honorItem, honerIndex) in showItem.honor" :key="honerIndex">
                        {{ honorItem }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="course_text"
                    v-if="showItem.course && showItem.course.length > 0"
                  >
                    <p class="tips">
                      主讲课程：<span
                        v-for="(item, index) in showItem.course"
                        :key="index"
                        >《{{ item }}》</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="teacher_list">
                <ul>
                  <li
                    v-for="teacher in teacherList[showIndex]"
                    :key="teacher.id"
                    :class="showItem.id == teacher.id ? 'active' : ''"
                    @click="getTeacherDetailId(teacher, showIndex)"
                  >
                    <p class="img"><img :src="teacher.avatar_34" alt="" /></p>
                    <div class="name_cont">
                      <p class="name">{{ teacher.name }}</p>
                      <p class="tips overflow_3">{{ teacher.introduce }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img class="bc3_home" src="../../assets/pc/qnz/bc3_home.png" alt="">
        </div>
        <!-- 创新工作室 -->
        <div v-for="(item, index) in contDataInfostudio" :key="item.id" style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box3">
            <div v-if="item.style_id != 6">
              <div class="title_cont flex">
                <div class="left_cont flex">
                  <p class="title">{{ item.name }}</p>
                </div>
                <div
                  class="more_btn cursor flex"
                  @click="$router.push('/activeList/'+item.id)"
                >
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div>
              </div>
              <div class="course_list">
                <ul>
                  <li v-for="list in item.list" :key="list.id" @click="goBox3Detail(list)">
                    <p class="img"><img :src="list.image" alt="" /></p>
                    <div>
                      <div class="cont_info">
                        <p class="title">{{ list.name }}</p>
                        <div class="text_info" v-if="list.desc">
                          <p class="name overflow_o">{{ list.desc }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="vert_box" v-if="advertList[index]">
                <div class="advert_image">
                  <a :href="advertList[index].pc_link" target="_blank">
                    <img :src="advertList[index].pc_image" width="100%" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分院建设 -->
        <div v-for="(item, index) in contDataInfoBranc" :key="item.id" style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box3" style="margin-top: 0;padding-top: 0;">
            <div v-if="item.style_id != 6">
              <div class="title_cont flex">
                <div class="left_cont flex">
                  <p class="title">{{ item.name }}</p>
                </div>
                <div
                  class="more_btn cursor flex"
                  @click="$router.push('/activeList/'+item.id)"
                >
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div>
              </div>
              <div class="course_list">
                <ul>
                  <li v-for="list in item.list" :key="list.id" @click="goBox3Detail(list)">
                    <p class="img"><img :src="list.image" alt="" /></p>
                    <div>
                      <div class="cont_info">
                        <p class="title">{{ list.name }}</p>
                        <div class="text_info" v-if="list.desc">
                          <p class="name overflow_o">{{ list.desc }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 企业研学 -->
        <div v-for="(item, index) in contDataInfo" :key="item.id" style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box3">
            <div v-if="item.style_id != 6">
              <div class="title_cont flex">
                <div class="left_cont flex">
                  <p class="title">{{ item.name }}</p>
                </div>
                <div
                  class="more_btn cursor flex"
                  @click="$router.push('/activeList/'+item.id)"
                >
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div>
              </div>
              <div class="course_list">
                <ul>
                  <li :style="item.id == '393'? 'height: 320px;' : 'height: 300px;'" v-for="list in item.list" :key="list.id" @click="goBox3Detail(list)">
                    <p class="img"><img :src="list.image" alt="" /></p>
                    <div :class="item.id == '393'? 'news_box' : ''">
                      <div class="cont_info">
                        <p class="title" :style="item.id == '393'? 'text-align: left;' : 'text-align: center;'">{{ list.name }}</p>
                        <p v-if="item.id == '393'" class="date">{{ list.created_at }}</p>
                        <el-button type="primary" class="news_btn" v-if="item.id == '393'">了解更多</el-button>
                        <div class="text_info" v-if="list.desc">
                          <p class="name overflow_o">{{ list.desc }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="vert_box" style="margin-top: 80px;" v-if="advertList[index]">
                <div class="advert_image">
                  <a :href="advertList[index].pc_link" target="_blank">
                    <img :src="advertList[index].pc_image" width="100%" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="content series-content  series-content6">
                <div class="title_cont flex">
                <div class="left_cont  flex">
                  <p class="title">{{ item.name }}</p>
                </div>
                <!-- <div
                  class="more_btn cursor flex"
                  @click="$router.push('/specialList?name=' + item.name + '&id=' + item.id)"
                >
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div> -->
              </div>
                <ul class="series-list">
                  <!-- <li
                    class="series-info"
                    v-for="subItem in item.list"
                    :key="subItem.data_id"
                    @click="$router.push('/seriesLessonsInfo/' + subItem.data_id)"
                  > -->
                  <li
                    class="series-info"
                    v-for="subItem in item.list"
                    :key="subItem.data_id"
                  >
                    <img style="object-fit:contain" :src="subItem.image" class="series-img" />
                    <div class="series-text">
                      <div class="series-title">{{ subItem.name }}</div>
                      <div class="marks">
                        <div class="mark" v-for="mark in subItem.tags" :key="mark">
                          {{ mark }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 创新思维与创新方法 -->
        <div style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box1">
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title">{{ showText }}</p>
              </div>
            </div>
            <div class="title_cont">
              <div  class="left_cont">
                <ul class="flex">
                  <li
                    class="cursor"
                    v-for="item in moduleList1"
                    :key="item.id"
                    :class="modalId1 == item.id ? 'active' : ''"
                    @click="changModal(item.id, '1')"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="course_list">
              <ul>
                <li
                  v-for="item in courseList1"
                  :key="item.id"
                  @click="goCourseDetail(item)"
                >
                  <p class="img"><img :src="item.image" alt="" /></p>
                  <div class="cont_info">
                    <p class="title overflow_o">{{ item.name }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="deep_btn"
              v-if="siteInfo.id == 73"
              @click="goNewUrl('https://npx.kskstudy.com')"
            >
              更多优质课程
            </div>
          </div>
        </div>
        <div style="width: 100%;background: #F7FAFF;">
          <div
            class="cont_item cont_box1"
            v-if="moduleList2.length > 0"
            style="margin-top: 50px"
          >
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title" v-if="siteInfo.id == 73">创新思维与创新方法</p>
                <p class="title" v-else>专业能力提升培训课程</p>
                <ul class="flex">
                  <li
                    class="cursor"
                    v-for="item in moduleList2"
                    :key="item.id"
                    :class="modalId2 == item.id ? 'active' : ''"
                    @click="changModal(item.id, '2')"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="course_list">
              <ul>
                <li
                  v-for="item in courseList2"
                  :key="item.id"
                  @click="goCourseDetail(item)"
                >
                  <p class="img"><img :src="item.image" alt="" /></p>
                  <div class="cont_info">
                    <p class="title overflow_o">{{ item.name }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="deep_btn"
              v-if="siteInfo.id == 73"
              @click="goNewUrl('https://npx.kskstudy.com')"
            >
              更多优质课程
            </div>
          </div>
        </div>
        <div style="width: 100%;background: #F7FAFF;">
          <div
            class="cont_item cont_box1"
            v-if="moduleList2.length > 0"
            style="margin-top: 50px"
          >
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title" v-if="siteInfo.id == 73">创新思维与创新方法</p>
                <p class="title" v-else>专业能力提升培训课程</p>
                <ul class="flex">
                  <li
                    class="cursor"
                    v-for="item in moduleList2"
                    :key="item.id"
                    :class="modalId2 == item.id ? 'active' : ''"
                    @click="changModal(item.id, '2')"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="course_list">
              <ul>
                <li
                  v-for="item in courseList2"
                  :key="item.id"
                  @click="goCourseDetail(item)"
                >
                  <p class="img"><img :src="item.image" alt="" /></p>
                  <div class="cont_info">
                    <p class="title overflow_o">{{ item.name }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="deep_btn"
              v-if="siteInfo.id == 73"
              @click="goNewUrl('https://npx.kskstudy.com')"
            >
              更多优质课程
            </div>
          </div>
        </div>
        
        <!-- 广告条 -->
        <a
          href="https://www.wjx.cn/vm/hiRVLgn.aspx#"
          target="_blank"
          v-if="siteInfo.id == 73"
        >
          <div class="line_img">
            <img src="@/assets/index/line_img1.png" alt="" />
          </div>
        </a>
        <!-- 创新成果展示 -->
        <!-- <div class="cont_item cont_box4 cont_box3">
          <div class="title_cont flex">
            <div class="left_cont">
              <p class="title">创新成果展示</p>
            </div>
            <div class="more_btn flex">
              <span>查看更多</span>
              <img src="@/assets/index/qnzright_icon.png" alt="" />
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li v-for="item in 3" :key="item.id">
                <p class="img"><img src="" alt="" /></p>
                <div class="cont_info">
                  <p class="title">专利申请与奖项申报</p>
                  <div class="text_info">
                    <p class="name">服务基层：服务一线 服务职工</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
        <!-- 工会工作应用 -->
        <div style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box3" style="padding-bottom: 60px;">
            <div>
              <div class="title_cont flex">
                <div class="left_cont flex">
                  <p class="title">工会工作应用</p>
                </div>
                <!-- <div class="more_btn cursor flex">
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div> -->
              </div>
              <div class="course_list">
                <ul>
                  <li>
                    <p class="img"><img src="../../assets/pc/qnz/lmzxq.jpg" alt="" /></p>
                    <div class="cont_info">
                      <p class="title">劳模助企行</p>
                    </div>
                  </li>
                  <li>
                    <p class="img"><img src="../../assets/pc/qnz/lmgl.jpg" alt="" /></p>
                    <div class="cont_info">
                      <p class="title">劳模管理系统</p>
                    </div>
                  </li>
                  <li>
                    <p class="img"><img src="../../assets/pc/qnz/cxcg.jpg" alt="" /></p>
                    <div class="cont_info">
                      <p class="title">创新成果管理系统</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻资讯 -->
        <div v-for="(item, index) in contDataInfonews" :key="item.id" style="width: 100%;background: #F7FAFF;">
          <div class="cont_item cont_box3" style="padding-bottom: 60px;">
            <div v-if="item.style_id != 6">
              <div class="title_cont flex">
                <div class="left_cont flex">
                  <p class="title">{{ item.name }}</p>
                </div>
                <div
                  class="more_btn cursor flex"
                  @click="$router.push('/activeList/'+item.id)"
                >
                  <span>查看更多</span>
                  <img src="@/assets/index/qnzright_icon.png" alt="" />
                </div>
              </div>
              <div class="course_list">
                <ul>
                  <li :style="item.id == '393'? 'height: 320px;' : 'height: 300px;'" v-for="list in item.list" :key="list.id" @click="goBox3Detail(list)">
                    <p class="img"><img :src="list.image" alt="" /></p>
                    <div :class="item.id == '393'? 'news_box' : ''">
                      <div class="cont_info">
                        <p class="title" :style="item.id == '393'? 'text-align: left;' : 'text-align: center;'">{{ list.name }}</p>
                        <p v-if="item.id == '393'" class="date">{{ list.created_at }}</p>
                        <el-button type="primary" class="news_btn" v-if="item.id == '393'">了解更多</el-button>
                        <div class="text_info" v-if="list.desc">
                          <p class="name overflow_o">{{ list.desc }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 公开考试 -->
        <div class="content-box clearfix examCont"  v-if="commonExamList.length >0">
          <div class="content cont_item lecturer-content">
            <div class="title_cont flex">
              <div class="left_cont flex">
                <p class="title">考试练兵</p>
              </div>
              <div
                class="more_btn cursor flex"
                @click="$router.push('/commonExam/list')"
              >
                <span>查看更多</span>
                <img src="@/assets/index/qnzright_icon.png" alt="" />
              </div>
            </div>
            <ul>
              <li v-for="examItem in commonExamList" :key="examItem.id">
                <p class="exam_name">{{examItem.exam_name}}</p>  
                <div class="time_">
                  <p><span>开始时间：</span>{{examItem.start_date}}</p>
                  <p><span>结束时间：</span>{{examItem.end_date}}</p>
                </div>     
                <div class="down_num">
                  <span>限制考试次数：</span>{{examItem.limit_desc}}
                </div> 
                <div class="btn" @click="$router.push('/commonExam/text/'+examItem.id)">去考试</div>    
              </li>
            </ul>
          </div>
        <img class="bc2_home" src="../../assets/pc/qnz/bc2_home.png" alt="">
        </div>
      </div>
      <!-- 广告条 -->
      <a
        href="https://www.wjx.cn/vm/hiRVLgn.aspx#"
        target="_blank"
        v-if="siteInfo.id == 73"
      >
        <div class="line_img">
          <img src="@/assets/index/line_img2.png" alt="" />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      bannerList: [],
      moduleList: [], //创新思维与创新方法 分类
      moduleList1: [],
      moduleList2: [],
      moduleList3: [],
      modalId1: [],
      modalId2: "",
      courseList1: [], //创新思维与创新方法
      courseList2: [], //创新思维与创新方法
      teacherList: [], //大师来了
      subItem: [], //安全
      showTeacher: [], //当前显示讲师
      contDataInfonew: [], // 各个模块
      contDataInfo: [], // 各个模块
      contDataInfonews: [], // 新闻模块
      contDataInfostudio: [], // 创新工作室
      contDataInfoBranc: [], // 分院建设
      swiperOptionsBanner: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      siteInfo: "",
      showText: "职工课堂",
      advertList: [], //广告位
      liveModule: [], //直播模块
      liveSelect: "", //直播模块选中
      modulelist: [],
      comAuth: false,
      ssologinid: '',
      ticket: '',
      commonExamList:[],//公开考试列表
      loadState: false,
      loadState1: false,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
    if (JSON.parse(localStorage.getItem('auth')) === null) {
      this.comAuth = false
      if (this.siteInfo.id === 106) {
        if (this.$route.query.source == 'qnapp') {
          this.logBtnGrjy()
        }
      }
    } else{
      this.comAuth = true
    }
    console.log(this.comAuth,'this.comAuth');
    this.showText = "职工课堂";
    this.getBanner();
    this.getLliveModule(); //获取黔南州工匠大讲堂
    this.getModule(); //获取首页模块
    this.getAdvert(); //获取广告条
    this.getOpenExam();//获取公开考试
  },
  methods: {
    //选择直播
    selectLive(data) {
      this.liveSelect = data;
    },
    // 贵人家园登录
    logBtnGrjy() {
			if (this.siteInfo.id === 106) {
				this.$axios.post(`/v1/gzLoginUrl`).then(res => {
					if(res.code == 0){
						window.location.href = res.data;
						localStorage.setItem('mLoginUrl', res.data);
					}else{
						this.ShowTipModal({
							text: res.msg,     //提示弹窗内容
						})
					}
				})
			}
    },
    // 贵人家园自动登录验证
    grnLoin() {
      this.$axios.get(`/v1/gzLoginValidate?ssologinid=${this.ssologinid}&ticket=${this.ticket}`).then((res) => {
        if (res.code == 0) {
          this.$axios.post(`/v1/gzLoginAuto`,{
            ssologinid: this.ssologinid,
          }).then((res) => {
            if (res.code == 0) {
              this.userInfo = res.data.user;
              localStorage.setItem("auth", JSON.stringify(res.data.auth));
              localStorage.setItem("user", JSON.stringify(res.data.user));
              localStorage.setItem("userInfo", JSON.stringify(res.data.user));
              this.$axios
                .post(`/v1/px/loginRecord`, {}, { useLog: true })
                .then(() => {
                  //登录记录
                  if (
                    this.$route.name == "Registered" ||
                    this.$route.name == "WelcomeRegistered"
                  ) {
                    this.CloseLoginModal();
                    this.$router.push("/index");
                  } else {
                    this.$router.go(0); //刷新页面
                  }
                });
              } else {
                this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        }  else if (res.code == 1) {
          this.$message({
          message: res.msg,
          type: 'error'
        });
        }
      });
    },
    getChannelNo() {
      if (this.comAuth) {
        this.$router.push(
        "/live/" + this.liveSelect.channel_no + "/" + this.liveSelect.id
      );
      } else {
        this.$message({
          message: '请登录后操作',
          type: 'warning'
        });
      }
    },
    changModal(item, num) {
      if (num == 1) {
        this.modalId1 = item;
      } else {
        this.modalId2 = item;
      }
      this.getCourseList(item, num);
    },
    goCourseDetail(item) {
      this.$router.push("/newRecordedInfo/" + item.data_id);
    },
    goBox3Detail(item) {
      if (item.data_id == 204) {
          console.log(1);
      } else {
        if (item.pc_link) {
          window.open(item.pc_link);
        } else {
          this.$router.push('/activeInfo/'+ item.data_id);
        }
      }
    },
    goNewUrl(url) {
      window.open(url);
    },
    // 获取banner图
    getBanner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "1",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    //获取黔南州工匠大讲堂
    getLliveModule() {
      this.$axios.post(`/v1/home/liveModule`).then((res) => {
        let nowDta = Date.now() / 1000;

        for (let i = 0; i < res.data.length; i++) {
          if (nowDta > res.data[i].live_time && nowDta < res.data[i].end_time) {
            res.data[i].liveNow = true;
          } else {
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      });
    },
    // 获取 创新思维与创新方法 列表
    getCourseList(id, num) {
      this.$axios.post(`/v1/home/moduleData`, { id: id }).then((res) => {
        if (num == 1) {
          this.courseList1 = res.data.data;
        } else {
          this.courseList2 = res.data.data;
        }
      });
    },
    // 获取 大师来了 多个列表
    async getTeacherData(teacherDataIDs) {
      let promises = [];
      for (let i = 0; i < teacherDataIDs.length; i++) {
        let promise = this.$axios.post(`/v1/home/moduleData`, { id: teacherDataIDs[i] })
          .then(res => {
            let data = res.data.length > 4 ? res.data.slice(0, 4) : res.data;
            data.forEach(el => {
              el.course = [];
              el.honor = [];
            });
            return data; // 返回处理后的数据
          });
        promises.push(promise);
      }
      try {
        const results = await Promise.all(promises); // 等待所有请求完成
        this.teacherList = results
        this.parseShowTeacher(this.teacherList); // 调用getTeacherDetail，传入完整的teacherList
      } catch (error) {
        console.error('Error fetching teacher data:', error);
      }
    },
    // 根据teacherId获取详情
    async findOneByTeacherId (teacher_id, currTeachers) {
      const res = await this.$axios.post(`/v1/home/teacherTag`, { teacher_id: teacher_id })
      if (res.code === 0) {
        currTeachers.course = res.data.course;
        currTeachers.honor = res.data.honor;
      }
    },
    // 解析每行第一个
    async parseShowTeacher(teacherList) {
      console.log("teacherList=", teacherList)
      let i = 0;
      for (const teachers of teacherList) {
        const currTeachers = teachers[0]
        await this.findOneByTeacherId(currTeachers.teacher_id, currTeachers)
        this.showTeacher[i] = currTeachers;
        i++
      }
      console.log(this.showTeacher, "showTeacher")
    },
    async getTeacherDetailId(item, index) {
      const currTeachers = item
      await this.findOneByTeacherId(currTeachers.teacher_id, currTeachers)
      this.showTeacher[index] = currTeachers
      this.$forceUpdate()
    },
    //获取首页模块
    getModule() {
      this.$axios.post(`/v1/home/module`).then((res) => {
        if (res.code == 0) {
          let contBox3 = [];
          let newsBox = []; // 新闻资讯
          let studioBox = []; // 创新工作室
          let BranchBox = []; // 分院建设
          let teacherData = [];
          res.data.forEach((el) => {
            if (el.type == 1) {
              this.moduleList.push(el);
            }
            if (el.type == 5) {
              teacherData.push(el.id);
            }
            if (el.type == 9 || el.type == 2) {
              if (el.id == '393') {
                newsBox.push(el);
              } else if (el.id == '416') {
                studioBox.push(el);
              } else if (el.id == '394') {
                BranchBox.push(el);
              } else if (el.id != '417'){
                contBox3.push(el);
              }
            }
          });
          this.moduleList1 = this.moduleList;
          this.modalId1 = this.moduleList1[0].id;
          this.getCourseList(this.modalId1, "1");
          this.getModuleDatanews(newsBox);
          this.getModuleDatastudio(studioBox);
          this.getModuleDataBranc(BranchBox);
          this.getModuleData(contBox3);
          this.getHeadData(this.moduleList1);
          this.getTeacherData(teacherData);
        }
      });
    },
    //获取各模块数据
    async getModuleData(moduleData) {
      let pre_page = undefined
      this.contDataInfo = moduleData;
      for (let i = 0; i < moduleData.length; i++) {
        if (moduleData[i].style_id === 6) {
          pre_page = 12
        } else {
          pre_page = undefined
        }
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,pre_page: pre_page
        });
        for (let i = 0; i < this.contDataInfo.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    // 获取新闻模块
    async getModuleDatanews(moduleData) {
      let pre_page = 6
      this.contDataInfonews = moduleData;
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,pre_page: pre_page
        });
        for (let i = 0; i < this.contDataInfonews.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfonews[i].id) {
                if (this.contDataInfonews[i].list) {
                  this.$set(this.contDataInfonews[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfonews[i], "list", []);
                  this.$set(this.contDataInfonews[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfonews[i].id) {
                if (this.contDataInfonews[i].list) {
                  this.$set(this.contDataInfonews[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfonews[i], "list", []);
                  this.$set(this.contDataInfonews[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    // 获取创新工作室
    async getModuleDatastudio(moduleData) {
      let pre_page = 6
      this.contDataInfostudio = moduleData;
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,pre_page: pre_page
        });
        for (let i = 0; i < this.contDataInfostudio.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfostudio[i].id) {
                if (this.contDataInfostudio[i].list) {
                  this.$set(this.contDataInfostudio[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfostudio[i], "list", []);
                  this.$set(this.contDataInfostudio[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfostudio[i].id) {
                if (this.contDataInfostudio[i].list) {
                  this.$set(this.contDataInfostudio[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfostudio[i], "list", []);
                  this.$set(this.contDataInfostudio[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    // 获取分院建设
    async getModuleDataBranc(moduleData) {
      let pre_page = undefined
      this.contDataInfoBranc = moduleData;
      for (let i = 0; i < moduleData.length; i++) {
        if (moduleData[i].style_id === 6) {
          pre_page = 12
        } else {
          pre_page = undefined
        }
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,pre_page: pre_page
        });
        for (let i = 0; i < this.contDataInfoBranc.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfoBranc[i].id) {
                if (this.contDataInfoBranc[i].list) {
                  this.$set(this.contDataInfoBranc[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfoBranc[i], "list", []);
                  this.$set(this.contDataInfoBranc[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfoBranc[i].id) {
                if (this.contDataInfoBranc[i].list) {
                  this.$set(this.contDataInfoBranc[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfoBranc[i], "list", []);
                  this.$set(this.contDataInfoBranc[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    // 获取有数据的头部
    async getHeadData(moduleData) {
      this.modulelist = moduleData;
      this.moduleList1 = [];
      if (this.modulelist <= 0) {
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
      }
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.modulelist.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
      }
      this.moduleList1 = this.modulelist.filter((item) => item.list);
    },
    // 获取广告条
    getAdvert() {
      this.$axios
        .post(`/v1/home/advert`, {
          type: "1",
        })
        .then((res) => {
          if (res.code == 0) {
            this.advertList = res.data;
          }
        });
    },
    // 获取公开考试
    getOpenExam(){
      this.$axios.post(`/v1/commonExam/list`,{page:1,per_page:4}).then(res=>{
        if(res.code==0){
          this.commonExamList = res.data.data
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.container_cont_box {
  background: #ffffff;
  position: relative;
  z-index: 1;
  .bc1_home {
    position: absolute;
    right: 0;
    top: -40px;
    z-index: 0;
    height: 510px;
    width: 25%;
  }
}
// banner图
.banner-content {
  width: 1200px;
  margin: 0px auto 0;
  padding-top: 20px;
  height: 440px;
  position: relative;
  z-index: 3;
  img {
    width: 100%;
    height: 440px;
    border-radius: 5px;
  }
}
.content_cont_box {
  // margin-top: 130px;
  position: relative;
  z-index: 1;
  .bc4_home {
    position: absolute;
    width: 100%;
    top: -375px;
    left: 0;
    z-index: -1;
  }
  // 直播
  .live-content {
    width: 100%;
    margin-top: 320px;
    background: #F7FAFF;
    margin-bottom: 15px;
    padding-bottom: 100px !important;
    // padding: 70px 0;
  }
  .live-list-content {
    .live-list {
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
      border-radius: 0px 6px 6px 0px;
      background: #fff;
      float: left;
      overflow-y: auto;
      padding: 10px;
      box-sizing: border-box;
    }
    .live-info {
      padding: 20px 10px 20px 10px;
      position: relative;
      &.active {
        background: #EFF3FF;
      }
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        background-color: rgba(0, 0, 0, 0.06);
        position: absolute;
        bottom: 0;
        left: 29px;
      }
      
    }
    .live-now {
      height: 30px;
      line-height: 30px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: 800;
      color: #f5a623;
      background: url(~@/assets/live-now-ico.png) no-repeat;
      background-size: 30px;
      background-position: left;
      margin-bottom: 7px;
    }
    .live-date {
      height: 20px;
      line-height: 22px;
      padding-left: 25px;
      background: url(~@/assets/shijian.png) no-repeat;
      background-size: 14px;
      background-position: 5px 5px;
      font-size: 17px;
      font-weight: 500;
      color: #333;
      margin-bottom: 12px;
    }
    .live-title {
      padding-left: 5px;
      font-size: 17px;
      color: #333;
      line-height: 22px;
      margin-bottom: 10px;
      a {
        color: #333;
      }
    }
    .live-lecturer {
      padding-left: 10px;
      font-size: 12px;
      color: #666;
    }
    .live-btn {
      width: 94px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      box-shadow: 0px 2px 4px 0px rgba(101, 67, 11, 0.2);
      border-radius: 6px;
      position: absolute;
      right: 20px;
      cursor: pointer;
      &.into {
        background: #ffc30b;
        color: #333;
      }
      &.subscribe {
        background: #254ed4;
        color: #fff;
      }
    }
  }
  .cont_item {
    width: 1200px;
    margin: 0 auto;
    .title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 34px;
      .title {
        min-width: 140px;
        font-size: 30px;
        color: #333;
        display: flex;
        align-items: flex-end;
        .title-text {
          position: relative;
          .text {
            position: relative;
            z-index: 1;
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 14px;
            border-radius: 7px;
            background-image: linear-gradient(90deg, #ffc30b, #fff7b9);
            position: absolute;
            bottom: 0;
            z-index: 0;
          }
        }
      }
      .sub-title {
        font-size: 16px;
        margin-left: 18px;
      }
      .more {
        height: 19.88px;
        padding-right: 22px;
        font-size: 14px;
        color: #999;
        background: url(~@/assets/arrows-right.png) no-repeat;
        background-size: 19.88px;
        background-position: right;
        cursor: pointer;
      }
    }
    .title_cont {
      width: 1200px;
      margin: 0 auto 52px;
      position: relative;
      justify-content: space-between;
      .left_cont {
        align-items: flex-end;
        flex: 1;
        justify-content: space-around;
        p.title {
          text-align: center;
          // font-size: 28px;
          font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
          font-weight: 500;
          color: #314EB2;
          font-weight: bold;
          font-size: 40px;
          color: #366CEC;
          line-height: 49px;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 60px;
            height: 2px;
            background: #366CEC;
            position: absolute;
            left: -75px;
            top: 24px;
          }
          &::before {
            content: "";
            display: block;
            width: 60px;
            height: 2px;
            background: #366CEC;
            position: absolute;
            right: -75px;
            top: 24px;
          }
        }
        ul {
          flex: 1;
          margin-left: 44px;
          //   flex-wrap: wrap;
          li {
            margin-right: 40px;
            font-size: 16px;
            font-weight: 400;
            position: relative;
            color: #7EA0F0;
            width: 134px;
            height: 50px;
            background: #F0F6FF;
            border-radius: 5px 5px 5px 5px;
            text-align: center;
            line-height: 50px;
          }
          li.active {
            color: #FFFFFF;
            background: #366CEC;
          }
          li:last-child {
            margin-right: 0;
          }
        }
      }
      .more_btn {
        position: absolute;
        right: 0;
        top: 13px;
        // width: 84px;
        // height: 32px;
        background: #ffffff;
        // border-radius: 275px 275px 275px 275px;
        // opacity: 1;
        // border: 1px solid #314EB2;
        justify-content: center;
        span {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #366CEC;
        }
        img {
          width: 8px;
          height: 10px;
          margin-left: 7px;
        }
      }
    }
  }
  // 创新思维与创新方法
  .cont_box1 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 120px;
    margin-top: 15px;
    .course_list {
      width: 100%;
      padding: 0px 0 100px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 278px;
          height: 230px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          // border: 1px solid #ebebeb;
          margin-right: 26px;
          margin-bottom: 24px;
          transition: all 0.2s ease-out;
          &:hover {
            position: relative;
            transform: translateY(-6px);
            box-shadow: 0px 0px 10px 1px rgba(113,113,113,0.16);
          }
          p.img {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px 8px 0 0;
            }
          }
          .cont_info {
            box-sizing: border-box;
            padding: 20px 16px 0;
            p.title {
              text-align: center;
              font-size: 18px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .teacher_name {
              margin-top: 10px;
              p {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              p.name {
                margin-right: 20px;
              }
            }
          }
        }
        li:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
    .deep_btn {
      width: 140px;
      margin: 32px auto 48px;
      height: 40px;
      background: linear-gradient(90deg, #0065df 0%, #27a5ff 99%);
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }

  // 大师来了
  .TeacherBox {
    padding-bottom: 266px;
    width: 100%;
    background: #F7FAFF;
    position: relative;
    z-index: 3;
    .bc3_home {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
    }
  }
  .cont_box2 {
    position: relative;
    z-index: 3;
    padding-top: 120px;
    .teacher_cont_box {
      background: url("~@/assets/pc/qnz/bgm.png") no-repeat;
      // background-size: 100% 444px;
      background-size: 100%;
      .show_teacher_info,
      .teacher_list {
        width: 1200px;
        margin: 0 auto;
      }
      .show_teacher_info {
        display: flex;
        padding-top: 51px;
        .left_img {
          width: 226px;
          height: 286px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .right_cont_info {
          flex: 1;
          margin-left: 40px;
          .name_info {
            display: flex;
            p.name {
              font-size: 26px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #3d3d3d;
            }
            .level_info {
              height: 28px;
              background: #f9f1e6;
              border-radius: 238px 238px 238px 238px;
              margin-left: 25px;
              padding-right: 12px;
              img {
                width: 36px;
                margin-left: -18px;
              }
              span {
                line-height: 28px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #e79e0c;
              }
            }
          }
          .text_info {
            margin-top: 21px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .tips_list {
            margin-top: 20px;
            ul {
              display: flex;
              align-items: center;
              li {
                margin-right: 16px;
                height: 28px;
                border-radius: 28px;
                opacity: 0.8;
                border: 1px solid #0065df;
                line-height: 28px;
                padding: 0 12px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #0065df;
              }
            }
          }
          .course_text {
            margin-top: 26px;
            p {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .teacher_list {
        margin-top: 43px;
        ul {
          display: flex;
          li {
            width: 285px;
            height: 132px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            // border: 2px solid #ebebeb;
            box-shadow: 0px 2px 4px 1px rgba(128,128,128,0.16);
            margin-right: 20px;
            box-sizing: border-box;
            padding: 13px 20px;
            display: flex;
            p.img {
              width: 84px;
              height: 106px;
              background: #000;
              opacity: 0.9;
              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                opacity: 0.7;
              }
            }
            .name_cont {
              flex: 1;
              margin-left: 20px;
              p.name {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #3d3d3d;
                line-height: 36px;
              }
              p.tips {
                font-size: 13px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
              }
            }
          }
          li.active {
            // border: 2px solid #79b1f6;
            box-shadow: 0px 2px 6px 1px rgba(54,108,236,0.3);
            p.img {
              img {
                opacity: 1;
              }
            }
          }
          li:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      .deep_btn {
        width: 182px;
        height: 40px;
        // background: linear-gradient(90deg, #0065df 0%, #27a5ff 99%);
        // border-radius: 4px 4px 4px 4px;
        margin: 40px auto 86px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  // 企业研学
  .cont_box3 {
    position: relative;
    z-index: 3;
    width: 1200px;
    margin: 48px auto 0px;
    margin-top: 110px;
    margin-top: 15px;
    padding-top: 120px;
    padding-bottom: 100px;
    .course_list {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 374px;
          // height: 338px;
          height: 300px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          // border: 1px solid #ebebeb;
          margin-right: 35px;
          margin-bottom: 40px;
          position: relative;
          transition: all .2s ease-out;
          &:hover {
            position: relative;
            transform: translateY(-6px);
            box-shadow: 0px 0px 10px 1px rgba(113,113,113,0.16);
            .news_box {
              bottom: 5px;
            }
            .news_btn {
              display: block;
            }
          }
          .news_btn {
            width: 81px;
            height: 28px;
            padding: 0;
            background: #366CEC;
            border-radius: 4px 4px 4px 4px;
            transition: all .1s ease-out;
            display: none;
            margin-top: 15px;
          }
          .news_box {
            position: absolute;
            // bottom: 38px;
            bottom: 0px;
            width: 100%;
            background: #fff;
            transition: all .4s ease-out;
          }
          p.img {
            width: 100%;
            height: 214px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px 8px 0 0;
            }
          }
          .cont_info {
            padding: 20px;
            p.new_title {
              text-align: left;
              height: 44px;
              font-size: 22px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
              overflow:hidden;/*内容超出后隐藏*/
              text-overflow:ellipsis;/*超出内容显示为省略号*/
              white-space:nowrap;/*文本不进行换行*/
            }
            p.title {
              text-align: center;
              height: 36px;
              font-size: 22px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
              overflow:hidden;/*内容超出后隐藏*/
              text-overflow:ellipsis;/*超出内容显示为省略号*/
              white-space:nowrap;/*文本不进行换行*/
            }
            .date {
              font-weight: 500;
              font-size: 18px;
              color: #666666;
              line-height: 35px;
            }
            .text_info {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #666666;
              margin-top: 14px;
            }
          }
        }
        li:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  .series-list {
  display: flex;
  justify-content: space-between;
  .series-info {
    width: 384px;
    height: 250px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      position: relative;
      transform: translateY(-6px);
      box-shadow: 0px 0px 10px 1px rgba(113,113,113,0.16);
    }
  }
  .series-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: -37px;
    object-fit: cover;
  }
  .series-text {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 26px 20px 20px;
    position: relative;
  }
  .series-title {
    font-size: 22px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 14px;
    position: absolute;
    bottom: 14px;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
  }
  .marks {
    overflow: hidden;
    margin-bottom: 34px;
    .mark {
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 10px 2px 10px 2px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      float: left;
      margin-right: 12px;
    }
  }
  .series-user-list {
    overflow: hidden;
  }
  .series-bottom {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .series-bottom-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
  }
  .series-price {
    font-size: 18px;
    color: #fff;
  }
  .series-user {
    float: left;
    margin-right: 10px;
    .series-user-img {
      width: 34px;
      height: 34px;
      border: 2px solid #fff;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
  // 安全
.series-content6{
  .series-list{
     justify-content: flex-start;
     flex-wrap: wrap;
     li{
      margin-right: 24px;
      margin-bottom: 24px;
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    .series-info{
      width: 280px;
      height: 240px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 0px solid #ebebeb;
    }
  }
}
  // 创新成果展示
  .cont_box4 {
    margin-top: 80px;
    margin-bottom: 91px;
  }

  // 广告条
  div.line_img {
    img {
      display: block;
      width: 100%;
    }
  }
}
// 公开考试样式
.examCont{
  padding-top: 120px !important;
  background: #F7FAFF;
  margin-top: 15px;
  ul{
    display: flex;
    li{
      width: 23%;
      background: #ffffff00;
      box-shadow: 0px 0px 14px 1px rgba(73, 110, 196, 0.14);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 20px;
      margin-right: 2%;
      background-image: url('../../assets/pc/qnz/ks_bc.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      p.exam_name{
        font-size: 22px;
        margin-bottom: 18px;
      }
      div.time_{
        span {
          color: #666666;
        }
        p{
          font-size: 16px;
          color: #333;
          margin-bottom: 10px;
          font-weight: 600;
        }
      }
      .down_num{
        span {
          color: #666666;
        }
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
        font-weight: 600;
      }
      div.btn{
        display: inline-block;
        background: #366CEC;
        padding: 5px 10px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        margin-left: 170px;
      }
    }
  }
}
.content-box {
  width: 100%;
  padding: 0 0 380px;
  padding-top: 0;
  // margin-top: 48px;
  position: relative;
  z-index: 3;
  .bc2_home {
    width: 100%;
    position: absolute;
    bottom: -40px;
    left: 0;
    z-index: -1
  }
  .data-list {
    width: 1224px;
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
  .title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
    .title {
      min-width: 140px;
      font-size: 30px;
      color: #314EB2;
      display: flex;
      align-items: flex-end;
      .title-text {
        position: relative;
        .text {
          position: relative;
          z-index: 1;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 14px;
          border-radius: 7px;
          background-image: linear-gradient(90deg, #ffc30b, #fff7b9);
          position: absolute;
          bottom: 0;
          z-index: 0;
        }
      }
    }
    .sub-title {
      font-size: 16px;
      margin-left: 18px;
    }
    .more_btn {
        // width: 84px;
        // height: 32px;
        background: #ffffff;
        // border-radius: 275px 275px 275px 275px;
        // opacity: 1;
        // border: 1px solid #314EB2;
        justify-content: center;
        span {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #314EB2;
        }
        img {
          width: 8px;
          height: 10px;
          margin-left: 7px;
        }
      }
  }
}
}
.lecturer-content {
  .lecturer-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.lecturer{
      justify-content: space-between;
      .lecturer-info{
        padding-bottom: 20px;
      }
      .lecturer-course{
        // height: 85px;
        -webkit-line-clamp: 6;
      }
      .lecturer-img {
        height: 282px;
        object-fit: cover;
      }
    }
  }
  .lecturer-info {
    width: 282px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  .openBox {
    width: 282px;
    height: 120px !important;
    .btn {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      p {
        width: 70px;
        height: 26px;
        background: #9f1106;
        color: #fff;
        font-size: 12px;
        line-height: 26px;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
  .lecturer-img {
    width: 100%;
    height: auto;
  }
  .lecturer-name {
    display: flex;
    align-items: center;
    padding: 14px 20px 8px;
    font-size: 16px;
    font-weight: 900;
    color: #333;
  }
  .lecturer-leve {
    padding: 2px 4px;
    background-color: rgba(255, 195, 11, 0.14);
    font-size: 12px;
    color: #f5a623;
    margin-left: 8px;
  }
  .lecturer-course {
    padding: 0 20px 0px;
    font-size: 14px;
    color: #666;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.overflow_o {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflow_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.overflow_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.banner::v-deep .swiper-pagination-bullet {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 1);
  // background: #fff;
  border-radius: 5px;
  opacity: 0.6;
  margin: 0 3px;
  // display: flex;
  // justify-content: flex-end;
}

.banner ::v-deep .swiper-pagination-bullets {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  bottom: 12px;
  box-sizing: border-box;
}

.banner::v-deep .swiper-pagination-bullet-active {
  width: 20px;
  background: rgba(255, 255, 255, 0.8);
  // opacity: 1;
}
</style>
